<template>
    <div>
        <div class="page-section" v-if="$route.path === '/practiceNotes'">
            <div class="title">{{$t('home.ExerciseHistory')}}</div>
            <div v-if="$i18n.locale == 'zh'">
                <q-table
                        :data="data"
                        :columns="columns"
                        row-key="id"
                        separator="cell"
                        @row-click="rowClickpoint"
                        :filter="filter"
                        :fullscreen="false"
                        :loading="loading"
                        flat
                        bordered
                        :rows-per-page-label="$t('home.RecordsPerPage')"
                        :rows-per-page-options="[10,20,50,100]"
                >
                    <template v-slot:top="props">
                        <q-input borderless dense debounce="300" color="primary" v-model="filter" :placeholder="$t('home.PleaseInputSearchCriteria')">
                            <template v-slot:append>
                                <q-icon name="search" />
                            </template>
                        </q-input>
                        <q-space />
                        <q-btn
                                flat round dense
                                :icon="props.inFullscreen ? 'fullscreen_exit' : 'fullscreen'"
                                @click="props.toggleFullscreen"
                                class="q-ml-md"
                        />
                    </template>
                    <template v-slot:body-cell-protein>
                        <td  class="q-td text-center">
                        <span   class="wrong-link" style="color:#FF7F4D; text-decoration: underline; cursor: pointer;">
                            {{$t('home.Detail')}}
                        </span>
                        </td>
                    </template>
                    <template v-slot:pagination="scope">
                        <q-btn
                                icon="chevron_left"
                                color="grey-8"
                                round
                                dense
                                flat
                                :disable="scope.isFirstPage"
                                @click="scope.prevPage"
                        />
                        <q-btn
                                icon="chevron_right"
                                color="grey-8"
                                round
                                dense
                                flat
                                :disable="scope.isLastPage"
                                @click="scope.nextPage"
                        />
                    </template>

                </q-table>
            </div>
            <div v-if="$i18n.locale == 'en'">
                <q-table
                        :data="data"
                        @row-click="rowClickpoint"
                        :columns="columnsEn"
                        row-key="id"
                        separator="cell"
                        :filter="filter"
                        :fullscreen="false"
                        :loading="loading"
                        flat
                        bordered
                        :rows-per-page-label="$t('home.RecordsPerPage')"
                        :rows-per-page-options="[10,20,50,100]"
                >
                    <template v-slot:top="props">
                        <q-input borderless dense debounce="300" style="width:220px" color="primary" v-model="filter" :placeholder="$t('home.PleaseInputSearchCriteria')">
                            <template v-slot:append>
                                <q-icon name="search" />
                            </template>
                        </q-input>
                        <q-space />
                        <q-btn
                                flat round dense
                                :icon="props.inFullscreen ? 'fullscreen_exit' : 'fullscreen'"
                                @click="props.toggleFullscreen"
                                class="q-ml-md"
                        />
                    </template>
                    <template v-slot:body-cell-protein>
                        <td  class="q-td text-center">
                        <span

                                class="wrong-link" style="color:#FF7F4D; text-decoration: underline; cursor: pointer;">
                            {{$t('home.Detail')}}
                        </span>
                        </td>
                    </template>
                    <template v-slot:pagination="scope">
                        <q-btn
                                icon="chevron_left"
                                color="grey-8"
                                round
                                dense
                                flat
                                :disable="scope.isFirstPage"
                                @click="scope.prevPage"
                        />
                        <q-btn
                                icon="chevron_right"
                                color="grey-8"
                                round
                                dense
                                flat
                                :disable="scope.isLastPage"
                                @click="scope.nextPage"
                        />
                    </template>

                </q-table>
            </div>
        </div>
        <router-view v-else></router-view>
    </div>

</template>

<script>
    export default {
        name: "practiceNotes",
        data () {
            return {
                loading: false,
                filter: '',
                rowCount: 10,
                columns: [
                    {
                        name: 'desc',
                        required: true,
                        label: '试卷名称',
                        align: 'left',
                        field:'exam_name_cn',
                        sortable: true
                    },
                    { name: 'calories', align: 'left', label: '你的得分', field: 'score', sortable: true,sort: (a, b) => parseInt(a, 10) - parseInt(b, 10) },
                    { name: 'fat', label: '答题耗时(分钟)', field: 'answer_time',align: 'left',},
                    { name: 'carbs', label: '开始时间', field: 'start_time',sortable: true,align: 'left', },
                    { name: 'protein', label: '操作', align: 'center' },
                ],
                columnsEn: [
                    {
                        name: 'desc',
                        required: true,
                        label: 'Exam Name',
                        align: 'left',
                        field:'exam_name_en',
                        sortable: true
                    },
                    { name: 'calories', align: 'left', label: 'Your Score', field: 'score', sortable: true,sort: (a, b) => parseInt(a, 10) - parseInt(b, 10) },
                    { name: 'fat', label: 'Duration（Minute）', field: 'answer_time',align: 'left',},
                    { name: 'carbs', label: 'Start Time', field: 'start_time',sortable: true,align: 'left', },
                    { name: 'protein', label: 'Result', align: 'center' },
                ],
                data: [

                ]
            }
        },
        created() {
            // console.log(data)
            this.getPracticeList()
        },
        methods: {
            // 获取练习记录
            getPracticeList(){
                this.loading = true
                this.$http.post('practice/list',).then(res =>{
                    this.loading = false
                    this.data = res.data
                }).catch(err =>{
                    console.log(err)
                    this.loading = false
                    this.data = []
                })
            },
            // emulate fetching data from server
            addRow () {
                this.loading = true
                setTimeout(() => {
                    const
                        index = Math.floor(Math.random() * (this.data.length + 1)),
                        row = this.original[Math.floor(Math.random() * this.original.length)]
                    if (this.data.length === 0) {
                        this.rowCount = 0
                    }
                    row.id = ++this.rowCount
                    const addRow = { ...row } // extend({}, row, { name: `${row.name} (${row.__count})` })
                    this.data = [ ...this.data.slice(0, index), addRow, ...this.data.slice(index) ]
                    this.loading = false
                }, 500)
            },

            removeRow () {
                this.loading = true
                setTimeout(() => {
                    const index = Math.floor(Math.random() * this.data.length)
                    this.data = [ ...this.data.slice(0, index), ...this.data.slice(index + 1) ]
                    this.loading = false
                }, 500)
            },
            rowClickpoint(evt, row, index){
                this.$router.push({path:'/examResult',query:{id:this.data[index].id}})
                console.log('index',evt, row, index)
            }
        }
    }
</script>

<style  lang="less">
    .q-field__native{
        font-size: 14px !important;
    }
.page-section{
    background-color: rgb(244, 244, 244);
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
}
    .title{
        font-size: 18px;
        color: #FF7F4D;
        margin-bottom: 15px;
    }
.page-section th{
    font-weight: 600;
    font-size: 14px;
    height: 30px;
}
.relative-position{
    height: auto !important;
}
.page-section td{
    height: 30px !important;
}
</style>